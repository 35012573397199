import { CloudCreditType } from 'types';
import { MenuItemType } from './menu';

const MENU_ITEMS_SUPER: MenuItemType[] = [
    { key: 'apps', label: 'Apps', isTitle: true },
    {
        //Gerenciamento de sistema
        key: 'apps-system',
        label: 'Sistema',
        isTitle: false,
        icon: 'mdi mdi-monitor',
        children: [
            {
                key: 'apps-system-info',
                label: 'Estado Geral',
                url: '/system',
                icon: 'mdi mdi-monitor-dashboard',
                creditCondition:
                    /* istanbul ignore next */
                    (cred) => {
                        return cred.length === Object.keys(CloudCreditType).length;
                    },
                parentKey: 'apps-system',
            },
            {
                key: 'apps-system-credentials',
                label: 'Credenciais Forge',
                url: '/forge',
                icon: 'mdi mdi-key-alert',
                creditCondition:
                    /* istanbul ignore next */
                    (cred) => {
                        return cred.length === Object.keys(CloudCreditType).length;
                    },
                parentKey: 'apps-system',
            },
            {
                key: 'apps-system-cloud-credits',
                label: 'Cloud Credits',
                url: '/cloud-credits',
                icon: 'mdi mdi-bitcoin',
                parentKey: 'apps-system',
            },
        ],
    },
    {
        //Gerenciamento de clientes
        key: 'apps-clients',
        label: 'Clientes',
        isTitle: false,
        icon: 'mdi mdi-account-box-multiple',
        creditCondition:
            /* istanbul ignore next */
            (cred) => {
                return cred.length === Object.keys(CloudCreditType).length;
            },
        url: '/clientes',
    },
    {
        // Gerenciamento super usuarios
        key: 'apps-manage',
        label: 'Gerenciamento',
        isTitle: false,
        icon: 'mdi mdi-cog-outline',
        creditCondition:
            /* istanbul ignore next */
            (cred) => {
                return cred.length === Object.keys(CloudCreditType).length;
            },
        children: [
            {
                key: 'apps-manage-users',
                label: 'Super Usuários',
                url: '/superusuarios',
                icon: 'mdi mdi-account-tie-hat-outline',
                parentKey: 'apps-manage',
            },
        ],
    },
    {
        // Logout da plataforma
        key: 'logout',
        label: 'Logout',
        isTitle: false,
        icon: 'mdi mdi-logout',
        url: 'account/logout',
    },
];

export { MENU_ITEMS_SUPER };
