import { BaseF } from 'types';
import { BaseState } from '../types';

export const saveListAll = <T extends BaseState, T2 extends BaseF>(state: T, field: keyof T, data: T2[]) => {
    state.loading = false;
    (state[field] as unknown as T2[]) = data;
};

export const saveListUnique = <T extends BaseState, T2>(
    state: T,
    field: keyof T,
    data: T2[],
    uniqueFields: Array<keyof T2>
) => {
    data.forEach((d) => {
        const item = (state[field] as unknown as T2[]).find((a) =>
            uniqueFields.map((f) => d[f] === a[f]).reduce((p, c) => p && c)
        );
        if (item) {
            (state[field] as unknown as T2[])[(state[field] as unknown as T2[]).indexOf(item)] = d;
        } else {
            (state[field] as unknown as T2[]).push(d);
        }
    });
    state.loading = false;
};

export const saveListOne = <T extends BaseState, T2 extends BaseF>(state: T, field: keyof T, data: T2) => {
    state.loading = false;
    saveListOneNoLoading(state, field, data);
};

export const saveListOneNoLoading = <T, T2 extends BaseF>(state: T, field: keyof T, data: T2) => {
    saveListOneNoLoadingID(state, field, data, 'id');
};

export const saveListOneNoLoadingID = <T, T2>(state: T, field: keyof T, data: T2, idfield: keyof T2) => {
    const item = (state[field] as unknown as T2[]).find((a) => a[idfield] === data[idfield]);
    if (item) {
        (state[field] as unknown as T2[])[(state[field] as unknown as T2[]).indexOf(item)] = data;
    } else {
        (state[field] as unknown as T2[]).push(data);
    }
};

export const updateOne = <T extends BaseState, T2 extends BaseF>(state: T, field: keyof T, data: T2) => {
    (state[field] as unknown as T2[])[
        (state[field] as unknown as T2[]).indexOf((state[field] as unknown as T2[]).find((a) => a.id === data.id)!)
    ] = data;
};

export const removeOne = <T extends BaseState, T2 extends BaseF>(state: T, field: keyof T, data: T2['id']) => {
    (state[field] as unknown as T2[]) = (state[field] as unknown as T2[]).filter((a) => a.id !== data);
};
