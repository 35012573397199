import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetState } from '../utils/reset-state';
import { anexos } from 'tests/mock';
import { AnexoF } from 'types';

interface AnexoState {
    anexos: AnexoF[];
}

const initialState: AnexoState = {
    anexos,
};

const AnexoSlice = createSlice({
    name: 'AnexoSlice',
    initialState,
    reducers: {
        reset: (state) => resetState(state, initialState),
        /* istanbul ignore next */
        create(state, action: PayloadAction<AnexoF>) {
            const ativo = action.payload;
            ativo.id = (Math.max(...state.anexos.map((a) => parseInt(a.id))) + 1).toString();
            state.anexos.push(ativo);
        },
        /* istanbul ignore next */
        update(state, action: PayloadAction<AnexoF>) {
            const index = state.anexos.indexOf(state.anexos.filter((a) => a.id === action.payload.id)[0]);
            state.anexos[index] = action.payload;
        },
        /* istanbul ignore next */
        remove(state, action: PayloadAction<string>) {
            state.anexos = state.anexos.filter((a) => a.id !== action.payload);
        },
    },
});

// export const { create, remove, update, reset } = AnexoSlice.actions;
export default AnexoSlice.reducer;
