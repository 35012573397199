import { WorkflowDto } from 'types';
/* istanbul ignore file */

export const workflows: WorkflowDto[] = [
    {
        id: '1',
        title: 'Redefinição de barragem',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat libero nisl, at consequat elit semper ac. Vivamus maximus, urna vel maximus pellentesque, mauris lectus pellentesque nulla, sed vestibulum nibh diam et orci.',
        status: 'active',
        assetId: '1',
        firstActivity: new Date().toUTCString(),
        doersIds: ['5'],
        approversIds: ['4'],
        observersIds: ['3'],
        isPeriodic: false,
        periodUnity: 'days',
        period: 30,
        deadlineUnity: 'days',
        deadline: 0,
    },
    {
        id: '2',
        title: 'Encapsulamento de bobinas',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat libero nisl, at consequat elit semper ac. Vivamus maximus, urna vel maximus pellentesque, mauris lectus pellentesque nulla, sed vestibulum nibh diam et orci.',
        status: 'inactive',
        assetId: '1',
        firstActivity: new Date().toUTCString(),
        doersIds: ['2'],
        approversIds: ['4'],
        observersIds: ['6'],
        isPeriodic: false,
        periodUnity: 'days',
        period: 30,
        deadlineUnity: 'days',
        deadline: 0,
    },
    {
        id: '3',
        title: 'Redefinição processos',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat libero nisl, at consequat elit semper ac. Vivamus maximus, urna vel maximus pellentesque, mauris lectus pellentesque nulla, sed vestibulum nibh diam et orci.',
        status: 'active',
        assetId: '2',
        firstActivity: new Date().toUTCString(),
        doersIds: ['1'],
        approversIds: ['3'],
        observersIds: ['5'],
        isPeriodic: false,
        periodUnity: 'days',
        period: 30,
        deadlineUnity: 'days',
        deadline: 0,
    },
    {
        id: '4',
        title: 'Definição de projetos',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat libero nisl, at consequat elit semper ac. Vivamus maximus, urna vel maximus pellentesque, mauris lectus pellentesque nulla, sed vestibulum nibh diam et orci.',
        status: 'active',
        assetId: '2',
        firstActivity: new Date().toUTCString(),
        doersIds: ['1'],
        approversIds: ['2'],
        observersIds: ['3'],
        isPeriodic: false,
        periodUnity: 'days',
        period: 30,
        deadlineUnity: 'days',
        deadline: 0,
    },
    {
        id: '5',
        title: 'Cálculo endeométrico',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat libero nisl, at consequat elit semper ac. Vivamus maximus, urna vel maximus pellentesque, mauris lectus pellentesque nulla, sed vestibulum nibh diam et orci.',
        status: 'inactive',
        assetId: '3',
        firstActivity: new Date().toUTCString(),
        doersIds: ['5'],
        approversIds: ['4'],
        observersIds: ['1'],
        isPeriodic: false,
        periodUnity: 'days',
        period: 30,
        deadlineUnity: 'days',
        deadline: 0,
    },
    {
        id: '6',
        title: 'Processamento de dados dos sensores',
        description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus volutpat libero nisl, at consequat elit semper ac. Vivamus maximus, urna vel maximus pellentesque, mauris lectus pellentesque nulla, sed vestibulum nibh diam et orci.',
        status: 'active',
        assetId: '3',
        firstActivity: new Date().toUTCString(),
        doersIds: ['4'],
        approversIds: ['2'],
        observersIds: ['3'],
        isPeriodic: false,
        periodUnity: 'days',
        period: 30,
        deadlineUnity: 'days',
        deadline: 0,
    },
];
