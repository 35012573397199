import { ActivityDto, AtividadePrioridade, AtividadeStatus } from 'types';
/* istanbul ignore file */

export const atividadesModel: ActivityDto[] = [
    {
        id: '1',
        title: 'Troca de Lampadas',
        description: 'Efetuar troca de iluminação ativo',
        priority: AtividadePrioridade.BAIXA,
        deadline: '11-22-2022',
        status: AtividadeStatus.ABERTA,
        model3dId: '1',
        responsableId: '1',
        fragId: 1,
        externalId: '',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',

        workflowId: '1',
    },
    {
        id: '2',
        title: 'Troca de Lampadas',
        description: 'Efetuar troca de iluminação ativo',
        priority: AtividadePrioridade.BAIXA,
        deadline: '11-22-2022',
        status: AtividadeStatus.ABERTA,
        model3dId: '1',
        responsableId: '1',
        fragId: 1,
        externalId: '',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        workflowId: '1',
    },
];

export const atividades: ActivityDto[] = [
    {
        id: '1',
        title: 'Troca de Lampadas',
        description: 'Efetuar troca de iluminação ativo',
        priority: AtividadePrioridade.BAIXA,
        deadline: '11-22-2022',
        status: AtividadeStatus.ABERTA,
        responsableId: '1',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        model3dId: null,
        fragId: null,
        externalId: '',
        workflowId: '1',
    },
    {
        id: '2',
        title: 'Calibrar Sensores',
        description: 'Efetuar Calibração manual de sensores de calor',
        priority: AtividadePrioridade.NORMAL,
        deadline: '10-25-2022',
        status: AtividadeStatus.ABERTA,
        responsableId: null,
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        model3dId: null,
        fragId: null,
        externalId: '',
        workflowId: '2',
    },
    {
        id: '3',
        title: 'Verificar Motor Parado',
        description: 'Verificar Motor que apresentou problemas durante periodo da manhã',
        priority: AtividadePrioridade.ALTA,
        deadline: '10-06-2022',
        status: AtividadeStatus.ABERTA,
        responsableId: '1',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        model3dId: null,
        fragId: null,
        externalId: '',
        workflowId: '3',
    },
    {
        id: '4',
        title: 'Efetuar reparo em equipamento',
        description: 'Efetuar reparo',
        priority: AtividadePrioridade.BAIXA,
        deadline: '11-06-2022',
        status: AtividadeStatus.EM_EXECUÇÃO,
        responsableId: '1',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        model3dId: null,
        fragId: null,
        externalId: '',
        workflowId: '4',
    },
    {
        id: '5',
        title: 'Verificar dispositivos de segurança',
        description: 'Verificar dispositivos',
        priority: AtividadePrioridade.BAIXA,
        deadline: '11-06-2022',
        status: AtividadeStatus.EM_AVALIAÇÃO,
        responsableId: '1',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        model3dId: null,
        fragId: null,
        externalId: '',
        workflowId: '5',
    },
    {
        id: '6',
        title: 'Troca de EPI',
        description: 'efetuar troca de epi',
        priority: AtividadePrioridade.BAIXA,
        deadline: '11-06-2022',
        status: AtividadeStatus.CONCLUIDA,
        responsableId: '1',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        model3dId: null,
        fragId: null,
        externalId: '',
        workflowId: '6',
    },
    {
        id: '7',
        title: 'Troca de lampadas saguão',
        description: 'troca',
        priority: AtividadePrioridade.BAIXA,
        deadline: '11-06-2022',
        status: AtividadeStatus.PARA_AVALIAÇÃO,
        responsableId: '1',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        model3dId: null,
        fragId: null,
        externalId: '',
        workflowId: '1',
    },
    {
        id: '8',
        title: 'Verificar Motor Parado',
        description: 'Verificar Motor que apresentou problemas durante periodo da manhã',
        priority: AtividadePrioridade.BAIXA,
        deadline: '10-06-2022',
        status: AtividadeStatus.ABERTA,
        responsableId: '1',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        model3dId: null,
        fragId: null,
        externalId: '',
        workflowId: '2',
    },
    {
        id: '9',
        title: 'Troca de Lampadas',
        description: 'Efetuar troca de iluminação ativo',
        priority: AtividadePrioridade.BAIXA,
        deadline: '12-22-2022',
        status: AtividadeStatus.ABERTA,
        responsableId: '1',
        messagesIds: [],
        objectId: null,
        elementName: null,
        ownerId: '1',
        assetId: '1',
        model3dId: null,
        fragId: null,
        externalId: '',
        workflowId: '1',
    },
];
