import { create, remove } from 'redux/features/alertas/alertas-slice';
import { v4 as uuidv4 } from 'uuid';
import { NovoAlertaF } from 'types';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';

export function useAlert(dispatch: Dispatch<AnyAction>) {
    const alert = (alert: NovoAlertaF, time = 5000) => {
        const id = uuidv4();
        dispatch(
            create({
                ...alert,
                id,
            })
        );
        if (time > 0) {
            setTimeout(() => {
                dispatch(remove(id));
            }, time);
        }
    };
    return alert;
}
