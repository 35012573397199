import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'redux/hooks';

const Root = () => {
    const {
        Auth: { user },
    } = useAppSelector((state) => state);
    const getRootUrl = () => {
        let url: string = 'ativos';
        if (user?.isSuperuser) url = 'system';
        return url;
    };
    const url = getRootUrl();

    return <Navigate to={`/${url}`} replace={true} />;
};

export default Root;
