import { useAppSelector } from 'redux/hooks';
import { AlertMessage } from './Item';

export function CustomAlerts() {
    const alertas = useAppSelector((state) => state.Alerts.alertas);
    return (
        <div style={{ position: 'fixed', zIndex: 99999, top: '80px', right: '10px', maxWidth: '400px' }}>
            {alertas.map((alerta, index) => {
                return (
                    <AlertMessage key={index} title={alerta.titulo} message={alerta.texto} variant={alerta.variant} />
                );
            })}
        </div>
    );
}
