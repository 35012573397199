import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AtivoF } from 'types';
import { resetState } from '../utils/reset-state';

export interface ForgePropertiesState {
    actualAsset: AtivoF['id'] | null;
}

const initialState: ForgePropertiesState = {
    actualAsset: null,
};

const ForgeModelAssetPropertiesSlice = createSlice({
    name: 'ForgeModelAssetPropertiesSlice',
    initialState,
    reducers: {
        reset: (state) => resetState(state, initialState),
        setActualAsset(state, { payload }: PayloadAction<AtivoF['id']>) {
            state.actualAsset = payload;
        },
    },
    extraReducers(builder) {},
});

export const { reset, setActualAsset } = ForgeModelAssetPropertiesSlice.actions;
export default ForgeModelAssetPropertiesSlice.reducer;
