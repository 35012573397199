import { MessageDto } from 'types';
/* istanbul ignore file */

export const comentarios: MessageDto[] = [
    {
        id: '1',
        userId: '1',
        updateAt: new Date('10-03-2022').toUTCString(),
        ownerId: '1',
        file: null,
        fileId: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus interdum posuere lorem ipsum dolor sit amet. Ipsum a arcu cursus vitae. Justo laoreet sit amet cursus sit.',
        activityId: '1',
        createAt: new Date('10-03-2022').toUTCString(),
    },
    {
        id: '2',
        userId: '2',
        updateAt: new Date('10-01-2022').toUTCString(),
        createAt: new Date('10-01-2022').toUTCString(),
        ownerId: '1',
        file: null,
        fileId: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus interdum posuere lorem ipsum dolor sit amet. Ipsum a arcu cursus vitae. Justo laoreet sit amet cursus sit.',
        activityId: '1',
    },
    {
        id: '3',
        userId: '2',
        updateAt: new Date('10-04-2022').toUTCString(),
        createAt: new Date('10-04-2022').toUTCString(),
        ownerId: '1',
        file: null,
        fileId: null,
        text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Faucibus interdum posuere lorem ipsum dolor sit amet. Ipsum a arcu cursus vitae. Justo laoreet sit amet cursus sit.',
        activityId: '2',
    },
];
