import { BaseF } from 'types/base';
import { OwnerF } from 'types/owner';
import { UsuarioF } from 'types/usuario';

export enum ActionType {
    CREATE = 'create',
    UPDATE = 'update',
    VALIDATE = 'validate',
    UPDATESTATUS = 'updatestatus',
    UPDATEIMAGE = 'updateimage',
    DELETE = 'delete',
}

export enum ModuleType {
    ASSETS = 'assets',
    ACTIVITIES = 'activities',
    MESSAGES = 'messages',
    MODELS = 'models',
    USERS = 'users',
    WORKFLOWS = 'workflows',
    SENSORS = 'sensors',
    OWNERS = 'owners',
    COMMENTS = 'comments',
    FORGE = 'forge',
    GIS = 'gis',
}
export enum StatusType {
    PENDING = 'pending',
    SUCCESS = 'success',
    FAILED = 'failed',
    TIMEOUT = 'timeout',
}

export interface ActionDto extends BaseF {
    module: ModuleType;
    objectId: string;
    action: ActionType;
    status: StatusType;
    userId: UsuarioF['id'];
    ownerId: OwnerF['id'];
    createdAt: string;
    updatedAt: string;
}
