import { useState } from 'react';
import { FileType } from './index';

const formatBytes = (bytes: number, decimals: number = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
};

export const fileToFileType = (file: File): FileType => {
    Object.assign(file, {
        preview: file['type'].split('/')[0] === 'image' ? URL.createObjectURL(file) : null,
        formattedSize: file.size > 0 ? formatBytes(file.size) : formatBytes(file.size, -1),
    });
    return file;
};

export default function useFileUploader(showPreview: boolean) {
    const [selectedFiles, setSelectedFiles] = useState<FileType[]>([]);

    const handleAcceptedFiles = (
        files: FileType[],
        callback?: (files: FileType[]) => void,
        customFileUpload?: (selected: FileType[], files: FileType[]) => Promise<FileType[]>
    ) => {
        var allFiles = files;

        if (showPreview) {
            files.map((file) => fileToFileType(file));
            if (customFileUpload) {
                customFileUpload([...selectedFiles], files).then((upFiles) => {
                    setSelectedFiles(upFiles);
                    if (callback) callback(upFiles);
                });
            } else {
                allFiles = [...selectedFiles];
                allFiles.push(...files);
                setSelectedFiles(allFiles);
                if (callback) callback(allFiles);
            }
        }
    };

    const removeFile = (file: FileType) => {
        const newFiles = [...selectedFiles];
        newFiles.splice(newFiles.indexOf(file), 1);
        setSelectedFiles(newFiles);
    };

    return {
        selectedFiles,
        handleAcceptedFiles,
        removeFile,
    };
}
