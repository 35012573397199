import { LoginRequest, LoginResponse, baseQueryWithReauth } from './query';
import {
    ActionDto,
    ActivityDto,
    AssetsDto,
    AtividadeF,
    AtividadeStatusValues,
    AtivoForm,
    AtivoUpdateForm,
    AtivoModelsPropertiesDto,
    BaseF,
    CloudCreditDto,
    CreateCloudCredit,
    CreateDocument,
    DisciplineDto,
    DocumentDto,
    GeoJsonDto,
    GeoJsonFormCreate,
    MarkerDto,
    MarkerFormCreate,
    MessageDto,
    Modelo3DCreateCall,
    Modelo3dDto,
    ObjectAccF,
    ObjectDto,
    OwnerDto,
    OwnerForm,
    SensorCreate,
    SensorDataCreate,
    SensorDataDto,
    SensorDto,
    SensorUpdateCall,
    ShapeFileFormCreate,
    SuperUserDto,
    SuperUsuarioForm,
    TagsDto,
    UpdateCloudCredit,
    UpdateDocument,
    UserDto,
    UsuarioChangePassF,
    UsuarioForm,
    WorkflowDto,
    WorkflowF,
    resetPassF,
    AccDocumentoF,
    VersionF,
} from 'types';
import { Limits } from 'components/CustomComponents/FormAtivo';
import { FetchBaseQueryError, createApi } from '@reduxjs/toolkit/dist/query/react';
import { allTags, Tags } from './tags';
import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
// import { isNumber } from 'lodash';
import { filterLimit, filterLimitUpdate } from './util';
import { AtivoStateDto } from 'types/api/status';
import { NodeData } from 'react-folder-tree';
import { ACCRequestOne } from 'types/accRequest';
import { setProfile } from '../auth/auth-slice';
import { getImage } from '../image/image-slice';
import { ThisExpression } from 'typescript';
import { positionShiftDto } from 'types/api/positionShift';
import { RootState } from 'redux/store';

export interface AccStatusResponse {
    status: boolean;
}

export interface AccCode {
    ownerId: string;
    code: string;
}

export interface ClientIdResponse {
    clientId: string;
}

export interface UpdateLimit {
    id: string;
    limitInfAlertOne?: number;
    limitSupAlertOne?: number;
    limitInfEmergencyOne?: number;
    limitSupEmergencyOne?: number;
    limitInfAlertTwo?: number;
    limitSupAlertTwo?: number;
    limitInfEmergencyTwo?: number;
    limitSupEmergencyTwo?: number;
    limitInfAlertThree?: number;
    limitSupAlertThree?: number;
    limitInfEmergencyThree?: number;
    limitSupEmergencyThree?: number;
}
export interface GeneralInfo extends BaseF {
    totalSize: number;
    files: number;
}
interface UpdateAssetLimitsCall extends Limits, BaseF {}
interface UpdateAvatar extends BaseF {
    image: File;
}

interface addProperties {
    id: AssetsDto['id'];
    properties: AtivoModelsPropertiesDto[];
}

interface SetCode {
    id: OwnerDto['id'];
    code: string;
}

interface UPdateDocumentCall extends UpdateDocument, BaseF {}

export interface UpdateStatus {
    id: ActivityDto['id'];
    newStatus: AtividadeStatusValues;
    newResponsableId: UserDto['id'] | null;
}

interface LinkAtividade {
    id: string;
    externalId: string;
    elementName: string;
    model3dId: string;
    objectId: number;
    fragId: number;
    positionShift: positionShiftDto;
    success?: (params: any) => void;
}

interface LinkSensor {
    id: string;
    externalId: string;
    elementName: string;
    objectId: number;
    fragId: number;
    positionShift: positionShiftDto;
    success?: (params: any) => void;
}
interface CallUpdateCredit extends UpdateCloudCredit, BaseF {}

export interface CreateComment {
    activity: ActivityDto['id'];
    text?: string;
    file?: File;
}

interface PropsData {
    start_date?: Date;
    end_date?: Date;
}
interface SensorInfo {
    sensorId: SensorDto['id'];
}

interface getSensorDataProps extends PropsData, SensorInfo {}

interface createSensorDataProps {
    sensorId: SensorDto['id'];
    sensorType: SensorDto['sensorType'];
    sensorData: SensorDataCreate[];
}

interface createSensorDataMultiFiles {
    assetId: AssetsDto['id'];
    files: File[];
}

interface createSensorDataOneFile {
    assetId: AssetsDto['id'];
    file: File;
}

interface createSensorFromFile {
    file: File;
}

interface createSensorDataByFileProps {
    sensorId: SensorDto['id'];
    file: File;
}

interface getSensorDataByAssetProps extends PropsData {
    assetId: AssetsDto['id'];
}

interface removeProps {
    sensorId: SensorDto['id'];
    sensorDataId: SensorDataDto['id'];
}

export interface removePropsMultiple {
    sensorId: SensorDto['id'];
    sensorDataIds: SensorDataDto['id'][];
}

interface ProjectsQuery {
    ownerId: string;
    hubId: string;
}

interface TopFoldersQuery {
    projectId: string;
    ownerId: string;
    hubId: string;
}

interface FolderContentsQuery {
    projectId: string;
    folderId: string;
    ownerId: string;
}

export interface ItemQuery {
    projectId: string;
    ownerId: string;
    itemId: string;
}

export const serviceApi = createApi({
    reducerPath: 'serviceApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: allTags,
    endpoints: (builder) => ({
        //auth endpoints
        login: builder.mutation<LoginResponse, LoginRequest>({
            extraOptions: {
                label: 'Login de Usuário',
                auth: true,
            },
            query: (credentials) => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: allTags,
        }),
        superLogin: builder.mutation<LoginResponse, LoginRequest>({
            extraOptions: {
                label: 'Login de Super Usuário',
                auth: true,
            },
            query: (credentials) => ({
                url: 'auth/superuser/login',
                method: 'POST',
                body: credentials,
            }),
            invalidatesTags: allTags,
        }),
        logout: builder.mutation<undefined, undefined>({
            extraOptions: {
                label: 'Logout de Usuário',
            },
            query: () => ({
                url: 'auth/logout',
                method: 'POST',
            }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                await queryFulfilled;
                dispatch(serviceApi.util.resetApiState());
            },
        }),
        profile: builder.query<UserDto, undefined>({
            extraOptions: {
                label: 'Receber Informações de Usuário',
            },
            query: () => ({ url: 'auth/profile' }),
            async onQueryStarted(arg, { queryFulfilled, dispatch, getState }) {
                const { data: profile } = await queryFulfilled;
                dispatch(setProfile(profile));

                if (profile.avatarId) {
                    const image = (getState() as RootState).Imagens.imagens.find(({ id }) => id === profile.avatarId);
                    if (!image || !image.error) {
                        dispatch(getImage(profile.avatarId));
                    }
                }
            },
            providesTags: [Tags.Profile],
        }),
        changeUserPassword: builder.mutation<UserDto, UsuarioChangePassF>({
            extraOptions: {
                label: 'Alterar Senha de Usuário',
            },
            query: ({ password, checkPassoword, currentPassword }) => ({
                url: 'users/update-password',
                method: 'PATCH',
                body: {
                    oldPassword: currentPassword,
                    newPassword: password,
                    newPasswordConfirm: checkPassoword,
                },
            }),
        }),
        changeSuperUserPassword: builder.mutation<UserDto, UsuarioChangePassF>({
            extraOptions: {
                label: 'Alterar Senha de Super Usuário',
            },
            query: ({ password, checkPassoword, currentPassword }) => ({
                url: 'superusers/update-password',
                method: 'PATCH',
                body: {
                    oldPassword: currentPassword,
                    newPassword: password,
                    newPasswordConfirm: checkPassoword,
                },
            }),
        }),
        //assets endpoints
        ativos: builder.query<AssetsDto[], undefined>({
            extraOptions: {
                label: 'Receber Ativos',
            },
            query: () => ({ url: 'assets' }),
            providesTags: [Tags.Asset],
        }),
        ativosByOwner: builder.query<AssetsDto[], string>({
            extraOptions: {
                label: 'Receber Ativos por Owner',
            },
            query: (ownerId) => ({ url: `assets/ByOwner/${ownerId}` }),
            providesTags: [Tags.Asset],
        }),
        ativo: builder.query<AssetsDto, string>({
            extraOptions: {
                label: 'Receber Ativo',
            },
            query: (id) => ({ url: `assets/${id}` }),
            providesTags: [Tags.Asset],
        }),
        updateAtivo: builder.mutation<AssetsDto, AtivoUpdateForm>({
            extraOptions: {
                label: 'Atualizar Ativo',
            },
            query: ({ id, ...data }) => {
                // if (Number.isFinite(Number(data.latitude)) && Number.isFinite(Number(data.longitude))) {
                //     (data as any).latitude = Number(data.latitude);
                //     (data as any).longitude = Number(data.longitude);
                // }
                return { url: `assets/${id}/update`, method: 'PATCH', body: data };
            },
            invalidatesTags: [Tags.Asset, Tags.User],
        }),
        createAtivo: builder.mutation<AssetsDto, AtivoForm>({
            extraOptions: {
                label: 'Criar Ativo',
            },
            query: ({ ativo: { id, imagem, ...data } }) => {
                // if (Number.isFinite(Number(latitude)) && Number.isFinite(Number(longitude))) {
                //     (data as any).latitude = Number(latitude);
                //     (data as any).longitude = Number(longitude);
                // }
                return { url: `assets`, method: 'POST', body: data };
            },
            invalidatesTags: [Tags.Asset, Tags.User],
        }),
        updateAtivoLimits: builder.mutation<AssetsDto, UpdateAssetLimitsCall>({
            extraOptions: {
                label: 'Atualizar Limites de Ativo',
            },
            query: ({ id, ...data }) => ({ url: `assets/${id}/update-limits`, method: 'PATCH', body: data }),
            invalidatesTags: [Tags.Asset],
        }),
        updateAtivoAvatar: builder.mutation<AssetsDto, UpdateAvatar>({
            extraOptions: {
                label: 'Atualizar Imagem de Ativo',
            },
            query: ({ id, image }) => {
                const form = new FormData();
                form.append('file', image);
                return {
                    url: `assets/${id}/avatar`,
                    method: 'PATCH',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.Asset],
        }),
        removeAtivo: builder.mutation<AssetsDto, string>({
            extraOptions: {
                label: 'Remover Ativo',
            },
            query: (id) => ({
                url: `assets/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.Asset],
        }),
        //assets properties endpoints
        ativoProperties: builder.query<AtivoModelsPropertiesDto[], AssetsDto['id']>({
            extraOptions: {
                label: 'Receber Propriedades de Ativo',
            },
            query: (id) => ({ url: `assets/${id}/search-name` }),
            providesTags: [Tags.AssetProps],
        }),
        addAtivoProperties: builder.mutation<AtivoModelsPropertiesDto, addProperties>({
            extraOptions: {
                label: 'Adicionar Propriedades de Ativo',
            },
            query: ({ id, properties }) => ({ url: `assets/${id}/search`, method: 'POST', body: properties }),
            invalidatesTags: [Tags.AssetProps],
        }),
        updateAtivoProperties: builder.mutation<AtivoModelsPropertiesDto, addProperties>({
            extraOptions: {
                label: 'Atualizar Propriedades de Ativo',
            },
            query: ({ id, properties }) => ({ url: `assets/${id}/update-search`, method: 'PATCH', body: properties }),
            invalidatesTags: [Tags.AssetProps],
        }),
        //owner endpoints
        owners: builder.query<OwnerDto[], undefined>({
            extraOptions: {
                label: 'Receber Clientes',
            },
            query: () => ({ url: `owners` }),
            providesTags: [Tags.Owner],
        }),
        owner: builder.query<OwnerDto, string>({
            extraOptions: {
                label: 'Receber Cliente',
            },
            query: (id) => ({ url: `owners/${id}` }),
            providesTags: [Tags.Owner],
        }),
        ownerInfo: builder.query<object, string>({
            extraOptions: {
                label: 'Receber informações de Cliente',
            },
            query: (id) => ({ url: `owners/${id}/info` }),
            providesTags: [Tags.Owner],
        }),
        createOwner: builder.mutation<OwnerDto, OwnerDto>({
            extraOptions: {
                label: 'Criar Cliente',
            },
            query: ({ id, cnpj, ...owner }) => ({
                url: 'owners',
                method: 'POST',
                body: { cnpj: cnpj, ...owner },
            }),
            invalidatesTags: [Tags.Owner],
        }),
        ownerSetCode: builder.mutation<OwnerDto, SetCode>({
            extraOptions: {
                label: 'Atualizar Codigo de Cliente',
            },
            query: ({ id, code }) => ({
                url: `forge/${id}/setAccCode`,
                method: 'POST',
                body: { code },
            }),
        }),
        updateOwner: builder.mutation<OwnerDto, OwnerForm>({
            extraOptions: {
                label: 'Atualizar Cliente',
            },
            query: ({ id, cnpj, ...owner }) => ({
                url: `owners/${id}/update`,
                method: 'PATCH',
                body: { cnpj: cnpj, ...owner },
            }),
            invalidatesTags: [Tags.Owner],
        }),
        removeOwner: builder.mutation<UserDto, string>({
            extraOptions: {
                label: 'Remover Cliente',
            },
            query: (id) => ({
                url: `owners/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.Owner],
        }),
        //user endpoints
        usuarios: builder.query<UserDto[], undefined>({
            extraOptions: {
                label: 'Receber Usuários',
            },
            query: () => ({ url: 'users' }),
            providesTags: [Tags.User],
        }),
        usuariosByOwner: builder.query<UserDto[], string>({
            extraOptions: {
                label: 'Receber Usuários por OwnerId',
            },
            query: (ownerId) => ({ url: `users/byOwner/${ownerId}` }),
            providesTags: [Tags.User],
        }),
        usuario: builder.query<UserDto, string>({
            extraOptions: {
                label: 'Receber Usuário',
            },
            query: (id) => ({ url: `users/${id}` }),
            providesTags: [Tags.User],
        }),
        updateUser: builder.mutation<UserDto, UsuarioForm>({
            extraOptions: {
                label: 'Atualizar Usuário',
            },
            query: ({ id, avatar, email, password, passwordConfirm, ownerId, ...user }) => ({
                url: `users/${id}/update`,
                method: 'PATCH',
                body: user,
            }),
            invalidatesTags: [Tags.User, Tags.Asset, Tags.Profile, Tags.Workflow, Tags.Atividade],
        }),
        resetUserPassword: builder.mutation<UserDto, resetPassF>({
            extraOptions: {
                label: 'Resetar Senha de Usuário',
            },
            query: ({ id, password, checkPassoword }) => ({
                url: `users/${id}/reset-password`,
                method: 'PATCH',
                body: {
                    newPassword: password,
                    newPasswordConfirm: checkPassoword,
                },
            }),
        }),
        updateUserAvatar: builder.mutation<UserDto, UpdateAvatar>({
            extraOptions: {
                label: 'Atualizar Imagem de Usuário',
            },
            query: ({ id, image }) => {
                const form = new FormData();
                form.append('file', image);
                return {
                    url: `users/${id}/avatar`,
                    method: 'PATCH',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.User],
        }),
        createUsuario: builder.mutation<UserDto, UsuarioForm>({
            extraOptions: {
                label: 'Criar Usuário',
            },
            query: ({ id, avatar, password, passwordConfirm, ...usuario }) => ({
                url: `users`,
                method: 'POST',
                body: {
                    ...usuario,
                    username: usuario.email,
                    password: password!,
                    passwordConfirm: passwordConfirm!,
                },
            }),
            invalidatesTags: [Tags.User, Tags.Asset],
        }),
        removeUsuario: builder.mutation<UserDto, string>({
            extraOptions: {
                label: 'Remover Usuário',
            },
            query: (id) => ({
                url: `users/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.User],
        }),
        //superuser endpoints
        superUsuarios: builder.query<SuperUserDto[], undefined>({
            extraOptions: {
                label: 'Receber Super Usuários',
            },
            query: () => ({ url: 'superusers' }),
            providesTags: [Tags.SuperUser],
        }),
        superUsuario: builder.query<SuperUserDto, SuperUserDto['id']>({
            extraOptions: {
                label: 'Receber Super Usuário',
            },
            query: (id) => ({ url: `superusers/${id}` }),
            providesTags: [Tags.SuperUser],
        }),
        updateSuperUser: builder.mutation<SuperUserDto, SuperUsuarioForm>({
            extraOptions: {
                label: 'Atualizar Super Usuário',
            },
            query: ({ id, avatar, email, password, passwordConfirm, ...user }) => ({
                url: `superusers/${id}/update`,
                method: 'PATCH',
                body: user,
            }),
            invalidatesTags: [Tags.SuperUser],
        }),
        resetSuperUserPassword: builder.mutation<UserDto, resetPassF>({
            extraOptions: {
                label: 'Resetar Senha de Super Usuário',
            },
            query: ({ id, password, checkPassoword }) => ({
                url: `superusers/${id}/reset-password`,
                method: 'PATCH',
                body: {
                    newPassword: password,
                    newPasswordConfirm: checkPassoword,
                },
            }),
        }),
        updateSuperUserAvatar: builder.mutation<UserDto, UpdateAvatar>({
            extraOptions: {
                label: 'Atualizar Imagem de Super Usuário',
            },
            query: ({ id, image }) => {
                const form = new FormData();
                form.append('file', image);
                return {
                    url: `superusers/${id}/avatar`,
                    method: 'PATCH',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.SuperUser],
        }),
        createSuperUsuario: builder.mutation<UserDto, SuperUsuarioForm>({
            extraOptions: {
                label: 'Criar Super Usuário',
            },
            query: ({ id, avatar, password, passwordConfirm, ...usuario }) => ({
                url: `superusers`,
                method: 'POST',
                body: {
                    ...usuario,
                    username: usuario.email,
                    password: password!,
                    passwordConfirm: passwordConfirm!,
                },
            }),
            invalidatesTags: [Tags.SuperUser],
        }),
        removeSuperUsuario: builder.mutation<UserDto, string>({
            extraOptions: {
                label: 'Remover Super Usuário',
            },
            query: (id) => ({
                url: `superusers/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.SuperUser],
        }),
        //model endpoints
        Modelos: builder.query<Modelo3dDto[], undefined | AssetsDto['id']>({
            extraOptions: {
                label: 'Receber Modelos',
            },
            query: (asset) => {
                let url = 'models3d';
                if (asset) url += `?asset=${asset}`;
                return { url };
            },
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                const { data: modelos } = await queryFulfilled;
                if (modelos.map((m) => m.status !== 'success').reduce((p, c) => p && c, false)) {
                    setTimeout(() => {
                        dispatch(serviceApi.util.invalidateTags([Tags.Model]));
                    }, 5000);
                }
            },
            providesTags: [Tags.Model],
        }),
        Modelo: builder.query<Modelo3dDto, string>({
            extraOptions: {
                label: 'Receber Modelo',
            },
            query: (id) => ({ url: `models3d/${id}` }),
            async onQueryStarted(arg, { queryFulfilled, dispatch }) {
                const { data: model } = await queryFulfilled;
                if (model.status !== 'success') {
                    setTimeout(() => {
                        dispatch(serviceApi.util.invalidateTags([Tags.Model]));
                    }, 5000);
                }
            },
            providesTags: [Tags.Model],
        }),
        updateModelo: builder.mutation<Modelo3dDto, Modelo3DCreateCall>({
            extraOptions: {
                label: 'Atualizar Modelo',
            },
            query: ({ id, ...modelo }) => {
                const form = new FormData();
                Object.keys(modelo).forEach((k) => {
                    if (modelo[k as keyof typeof modelo] !== undefined) {
                        form.append(k, modelo[k as keyof typeof modelo] as any);
                    }
                });
                return {
                    url: `models3d/${id}/update`,
                    method: 'PATCH',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.Model],
        }),
        createModelo: builder.mutation<Modelo3dDto, Modelo3DCreateCall>({
            extraOptions: {
                label: 'Criar Modelo',
            },
            query: ({ id, ...modelo }) => {
                const form = new FormData();
                Object.keys(modelo).forEach((k) => {
                    if (modelo[k as keyof typeof modelo] !== undefined) {
                        form.append(k, modelo[k as keyof typeof modelo] as any);
                    }
                });
                return {
                    url: `models3d`,
                    method: 'POST',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.Model],
        }),
        removeModelo: builder.mutation<Modelo3dDto, string>({
            extraOptions: {
                label: 'Remover Modelo',
            },
            query: (id) => ({
                url: `models3d/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.Model],
        }),
        //disciple endpoints
        disciplinas: builder.query<DisciplineDto[], undefined>({
            extraOptions: {
                label: 'Receber Disciplinas',
            },
            query: () => ({ url: 'disciplines' }),
            providesTags: [Tags.Discipline],
        }),
        createDisciplina: builder.mutation<DisciplineDto, string>({
            extraOptions: {
                label: 'Criar Disciplina',
            },
            query: (name) => ({
                url: `disciplines`,
                method: 'POST',
                body: {
                    name,
                },
            }),
            invalidatesTags: [Tags.Discipline],
        }),
        removeDisciplina: builder.mutation<DisciplineDto, string>({
            extraOptions: {
                label: 'Remover Disciplina',
            },
            query: (id) => ({
                url: `disciplines/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.Discipline],
        }),
        //workflow endpoints
        workflows: builder.query<WorkflowDto[], undefined>({
            extraOptions: {
                label: 'Receber Workflows',
            },
            query: () => ({ url: 'workflows' }),
            providesTags: [Tags.Workflow],
        }),
        workflow: builder.query<WorkflowDto, string>({
            extraOptions: {
                label: 'Receber Workflow',
            },
            query: (id) => ({ url: `workflows/${id}` }),
            providesTags: [Tags.Workflow],
        }),
        updateWorkflow: builder.mutation<WorkflowDto, WorkflowF>({
            extraOptions: {
                label: 'Atualizar Workflow',
            },
            query: ({ id, assetId, ...workflow }) => ({
                url: `workflows/${id}/update`,
                method: 'PATCH',
                body: {
                    ...workflow,
                    firstActivity: '2022-10-17T18:07:58.167Z',
                },
            }),
            invalidatesTags: [Tags.Workflow],
        }),
        createWorkflow: builder.mutation<WorkflowDto, WorkflowF>({
            extraOptions: {
                label: 'Criar Workflow',
            },
            query: ({ id, ...workflow }) => ({
                url: `workflows`,
                method: 'POST',
                body: {
                    ...workflow,
                    firstActivity: '2022-10-17T18:07:58.167Z',
                },
            }),
            invalidatesTags: [Tags.Workflow],
        }),
        removeWorkflow: builder.mutation<WorkflowDto, string>({
            extraOptions: {
                label: 'Remover Workflow',
            },
            query: (id) => ({
                url: `workflows/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.Workflow],
        }),
        //atividades endpoints
        Atividades: builder.query<ActivityDto[], undefined>({
            extraOptions: {
                label: 'Receber Atividades',
            },
            query: () => ({ url: 'activities' }),
            providesTags: [Tags.Atividade],
        }),
        atividadesByModels: builder.query<ActivityDto[], Modelo3dDto['id'][]>({
            extraOptions: {
                label: 'Receber Atividades por Modelos',
            },
            queryFn: async (ids, _queryApi, _extraOptions, baseQuery) => {
                const { data } = (await baseQuery('activities')) as QueryReturnValue<
                    ActivityDto[],
                    FetchBaseQueryError,
                    {}
                >;
                return { data: data?.filter(({ model3dId }) => model3dId && ids.includes(model3dId)) || [] };
            },
            providesTags: [Tags.Atividade],
        }),
        atividadesByModel: builder.query<ActivityDto[], Modelo3dDto['id']>({
            extraOptions: {
                label: 'Receber Atividades por Modelo',
            },
            queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
                const { data } = (await baseQuery('activities')) as QueryReturnValue<
                    ActivityDto[],
                    FetchBaseQueryError,
                    {}
                >;
                return { data: data?.filter(({ model3dId }) => model3dId && id === model3dId) || [] };
            },
            providesTags: [Tags.Atividade],
        }),
        atividadesByAsset: builder.query<ActivityDto[], AssetsDto['id']>({
            extraOptions: {
                label: 'Receber Atividades por Ativo',
            },
            queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
                const { data } = (await baseQuery('activities')) as QueryReturnValue<
                    ActivityDto[],
                    FetchBaseQueryError,
                    {}
                >;
                return { data: data?.filter(({ assetId }) => id === assetId) || [] };
            },
            providesTags: [Tags.Atividade],
        }),
        Atividade: builder.query<ActivityDto, string>({
            extraOptions: {
                label: 'Receber Atividade',
            },
            query: (id) => ({ url: `activities/${id}` }),
            providesTags: [Tags.Atividade],
        }),
        updateAtividade: builder.mutation<ActivityDto, AtividadeF>({
            extraOptions: {
                label: 'Atualizar Atividade',
            },
            query: ({ id, deadline, responsableId, fragId, objectId, status, anexos, workflowId, ...act }) => ({
                url: `activities/${id}/update`,
                method: 'PATCH',
                body: {
                    ...act,
                    deadline: deadline.toISOString(),
                },
            }),
            invalidatesTags: [Tags.Atividade],
        }),
        updateAtividadeStatus: builder.mutation<ActivityDto, UpdateStatus>({
            extraOptions: {
                label: 'Atualizar Status de Atividade',
            },
            query: ({ id, newStatus, newResponsableId }) => ({
                url: `activities/${id}/UpdateStatus`,
                method: 'PATCH',
                body: { newResponsableId, newStatus },
            }),
            invalidatesTags: [Tags.Atividade],
        }),
        createAtividade: builder.mutation<ActivityDto, AtividadeF>({
            extraOptions: {
                label: 'Criar Atividade',
            },
            query: ({ id, deadline, responsableId, anexos, ...act }) => ({
                url: `activities`,
                method: 'POST',
                body: {
                    ...act,
                    deadline: deadline.toISOString(),
                },
            }),
            invalidatesTags: [Tags.Atividade],
        }),
        removeAtividade: builder.mutation<ActivityDto, string>({
            extraOptions: {
                label: 'Remover Atividade',
            },
            query: (id) => ({
                url: `activities/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.Atividade],
        }),
        addAtividadeLink: builder.mutation<ActivityDto, LinkAtividade>({
            extraOptions: {
                label: 'Criar Link de Atividade',
            },
            query: ({ id, elementName, model3dId, externalId, fragId, objectId, positionShift, success }) => ({
                url: `activities/${id}/update`,
                method: 'PATCH',
                body: {
                    positionShift,
                    elementName,
                    externalId,
                    model3dId,
                    objectId,
                    fragId,
                },
            }),
            invalidatesTags: [Tags.Atividade],
        }),
        removeAtividadeLink: builder.mutation<ActivityDto, string>({
            extraOptions: {
                label: 'Remover Link de Atividade',
            },
            query: (id) => ({
                url: `activities/${id}/update`,
                method: 'PATCH',
                body: {
                    positionShift: null,
                    elementName: null,
                    externalId: null,
                    model3dId: null,
                    objectId: null,
                    fragId: null,
                },
            }),
            invalidatesTags: [Tags.Atividade],
        }),
        //comments endpoints
        commentsCount: builder.query<number, string[]>({
            extraOptions: {
                label: 'Receber Numero de Comentarios por Atividade',
            },
            queryFn: async (ids, _queryApi, _extraOptions, baseQuery) => {
                const results = await Promise.all(ids.map((id) => baseQuery(`messages/byActivity/${id}`)));
                const data = (results as QueryReturnValue<MessageDto[], FetchBaseQueryError, {}>[])
                    .map(({ data }) => data?.length || 0)
                    .reduce((p, c) => p + c, 0);
                return { data };
            },
            providesTags: [Tags.Message],
        }),
        commentsWithFileByOwner: builder.query<MessageDto[], string>({
            extraOptions: {
                label: 'Receber Comentarios por Cliente com Arquivos',
            },
            query: (id) => ({ url: `messages/byOwnerWithFile/${id}` }),
            providesTags: [Tags.Message],
        }),
        comments: builder.query<MessageDto[], string>({
            extraOptions: {
                label: 'Receber Comentarios por Atividade',
            },
            query: (id) => ({ url: `messages/byActivity/${id}` }),
            providesTags: [Tags.Message],
        }),
        comment: builder.query<MessageDto, MessageDto['id']>({
            extraOptions: {
                label: 'Receber Comentario',
            },
            query: (id) => ({ url: `messages/${id}` }),
            providesTags: [Tags.Message],
        }),
        commentsWithFiles: builder.query<MessageDto[], string>({
            extraOptions: {
                label: 'Receber Comentarios com Arquivos',
            },
            query: (id) => ({ url: `messages/byActivityWithFile/${id}` }),
            providesTags: [Tags.Message],
        }),
        commentsWithFilesActiList: builder.query<MessageDto[], ActivityDto['id'][]>({
            extraOptions: {
                label: 'Receber Comentarios por Atividade com Arquivos',
            },
            queryFn: async (ids, _queryApi, _extraOptions, baseQuery) => {
                const results = await Promise.all(ids.map((id) => baseQuery(`messages/byActivityWithFile/${id}`)));
                const data = (results as QueryReturnValue<MessageDto[], FetchBaseQueryError, {}>[])
                    .map(({ data }) => data!)
                    .filter((d) => d !== undefined)
                    .reduce((p, c) => p!.concat(c!), []);
                return { data: data };
            },
            providesTags: [Tags.Message],
        }),
        createComment: builder.mutation<MessageDto, CreateComment>({
            extraOptions: {
                label: 'Criar Comentario',
            },
            query: ({ activity, text, file }) => {
                const form = new FormData();
                form.append('activityId', activity);
                if (text) form.append('text', text);
                if (file) form.append('file', file);
                return {
                    url: `messages`,
                    method: 'POST',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.Message, Tags.Atividade],
        }),
        //sensores endpoint
        sensores: builder.query<SensorDto[], undefined>({
            extraOptions: {
                label: 'Receber Sensores',
            },
            query: () => ({ url: 'sensors' }),
            providesTags: [Tags.Sensor],
        }),
        sensoresByAsset: builder.query<SensorDto[], AssetsDto['id']>({
            extraOptions: {
                label: 'Receber Sensores por Ativo',
            },
            query: (ativoId) => ({ url: `sensors/asset/${ativoId}` }),
            providesTags: [Tags.Sensor],
        }),
        sensor: builder.query<SensorDto, string>({
            extraOptions: {
                label: 'Receber Sensor',
            },
            query: (id) => ({ url: `sensors/${id}` }),
            providesTags: [Tags.Sensor],
        }),
        createSensor: builder.mutation<SensorDto, SensorCreate>({
            // Creation of sensor with or without data
            extraOptions: { label: 'Criar Sensor com Dados de Arquivos' },
            query: (sensorCreate) => {
                const { heights, success, ...sensor } = filterLimit(sensorCreate);

                let form: any = {};
                Object.entries(sensor).forEach((entry) => {
                    const [key, value] = entry;
                    if (sensor[key as keyof typeof sensor] !== undefined) {
                        form[key] = value;
                    }
                });
                if (heights && heights.length > 0) form['heights'] = heights;
                return {
                    url: 'sensors/',
                    method: 'POST',
                    body: form,
                };
            },
            invalidatesTags: [Tags.Sensor],
        }),
        updateSensor: builder.mutation<SensorDto, SensorUpdateCall>({
            // Update sensor with or without data
            extraOptions: {
                label: 'Atualizar Sensor eventualmente com Dados',
            },
            query: (sensorUpdate) => {
                const id = sensorUpdate.id;
                const { heights, success, ...updated } = filterLimitUpdate(sensorUpdate);
                let form: any = {};

                Object.entries(updated).forEach((entry) => {
                    const [key, value] = entry;
                    if (
                        !['id', 'assetId', 'data', 'model3dId'].includes(key) &&
                        updated[key as keyof typeof updated] !== undefined
                    ) {
                        form[key] = value;
                    }
                });
                if (heights && heights.length > 0) form['heights'] = heights;
                return {
                    url: `sensors/${id}`,
                    method: 'PATCH',
                    body: form,
                };
            },
            invalidatesTags: [Tags.Sensor],
        }),
        removeSensor: builder.mutation<SensorDto, string>({
            extraOptions: {
                label: 'Remover Sensor',
            },
            query: (id) => ({
                url: `sensors/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.Sensor],
        }),
        addSensorLink: builder.mutation<SensorDto, LinkSensor>({
            extraOptions: {
                label: 'Criar Link de Sensor',
            },
            query: ({ id, externalId, elementName, fragId, objectId, positionShift }) => ({
                url: `sensors/${id}`,
                method: 'PATCH',
                body: {
                    positionShift,
                    elementName,
                    externalId,
                    objectId,
                    fragId,
                },
            }),
            invalidatesTags: [Tags.Sensor],
        }),
        removeSensorLink: builder.mutation<SensorDto, SensorDto['id']>({
            extraOptions: {
                label: 'Remover Link de Sensor',
            },
            query: (id) => ({
                url: `sensors/${id}`,
                method: 'PATCH',
                body: {
                    positionShift: null,
                    externalId: null,
                    objectId: null,
                    fragId: null,
                },
            }),
            invalidatesTags: [Tags.Sensor],
        }),
        //sensor data endpoints
        sensorData: builder.query<SensorDataDto[], getSensorDataProps>({
            extraOptions: {
                label: 'Receber Dados de Sensor',
            },
            query: ({ sensorId, start_date, end_date }) => {
                let url = `sensors/${sensorId}/data`;
                if (start_date || end_date) url += '?';
                if (start_date) url += `start_date=${start_date.toISOString()}`;
                if (start_date && end_date) url += '&';
                if (end_date) url += `end_date=${end_date.toISOString()}`;
                return { url };
            },
            providesTags: [Tags.SensorData],
        }),
        sensorDataByAsset: builder.query<SensorDataDto[], getSensorDataByAssetProps>({
            extraOptions: {
                label: 'Receber Dados de Sensor por Ativo',
            },
            query: ({ assetId, start_date, end_date }) => {
                let url = `sensors/asset/${assetId}/data`;
                if (start_date || end_date) url += '?';
                if (start_date) url += `start_date=${start_date.toISOString()}`;
                if (start_date && end_date) url += '&';
                if (end_date) url += `end_date=${end_date.toISOString()}`;
                return { url };
            },
            providesTags: [Tags.SensorData],
        }),
        createSensorData: builder.mutation<SensorDataDto, createSensorDataProps>({
            extraOptions: {
                label: 'Criar Dados de Sensor',
            },
            query: ({ sensorId, sensorType, sensorData }) => ({
                url: `sensors/${sensorId}/${sensorType}`,
                method: 'POST',
                body: sensorData,
            }),
            invalidatesTags: [Tags.SensorData],
        }),
        createSensorDataMultiFiles: builder.mutation<any, createSensorDataMultiFiles>({
            extraOptions: {
                label: 'Criar Dados de Sensor por arquivos multiplos',
            },
            query: ({ assetId, files }) => {
                const form = new FormData();
                form.append('assetId', assetId);
                files.forEach((file) => {
                    form.append('files', file);
                });
                return {
                    url: `sensors/multiple-data-from-files`,
                    method: 'POST',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.SensorData],
        }),
        createSensorDataOneFile: builder.mutation<any, createSensorDataOneFile>({
            extraOptions: {
                label: 'Criar Dados de Sensor por batelada',
            },
            query: ({ assetId, file }) => {
                const form = new FormData();
                form.append('assetId', assetId);
                form.append('file', file);
                return {
                    url: `sensors/multiple-data-from-file`,
                    method: 'POST',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.SensorData],
        }),
        createSensorsFromFile: builder.mutation<any, createSensorFromFile>({
            extraOptions: {
                label: 'Criar Sensores por batelada',
            },
            query: ({ file }) => {
                const form = new FormData();
                form.append('file', file);
                return {
                    url: `sensors/from-file`,
                    method: 'POST',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.Sensor],
        }),
        createSensorDataByFile: builder.mutation<SensorDataDto, createSensorDataByFileProps>({
            extraOptions: {
                label: 'Criar Dados de Sensor por Arquivo',
            },
            query: ({ sensorId, file }) => {
                const form = new FormData();
                form.append('file', file);
                return {
                    url: `sensors/${sensorId}/data-file`,
                    method: 'POST',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
        }),
        removeSensorData: builder.mutation<SensorDataDto, removeProps>({
            extraOptions: {
                label: 'Remover Dado de Sensor',
            },
            query: ({ sensorId, sensorDataId }) => ({
                url: `sensors/${sensorId}/data/${sensorDataId}`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.SensorData],
        }),
        removeSensorDataMultiple: builder.mutation<SensorDataDto[], removePropsMultiple>({
            extraOptions: {
                label: 'Remover Dados de Sensor',
            },
            query: ({ sensorId, sensorDataIds }) => ({
                url: `sensors/${sensorId}/data`,
                method: 'DELETE',
                body: { sensorDataIds: sensorDataIds },
            }),
            invalidatesTags: [Tags.SensorData],
        }),
        //Multipolygons endpoints
        multiPolygons: builder.query<GeoJsonDto[], AssetsDto['id']>({
            extraOptions: {
                label: 'Receber Poligonos Gis',
            },
            query: (assetId) => ({ url: `gis/multipolygon/${assetId}` }),
            providesTags: [Tags.MultiPolygon],
        }),
        createMultiPolygon: builder.mutation<GeoJsonDto, GeoJsonFormCreate>({
            extraOptions: {
                label: 'Criar Poligonos',
            },
            query: ({ data, ...geo }) => ({
                url: `gis/multipolygon`,
                method: 'POST',
                body: {
                    data: {
                        type: 'MultiPolygon',
                        coordinates: data.map((a1) =>
                            a1.map((a2) =>
                                a2.map((a3) => a3.map((a4) => Number(a4.replace(' ', '').replace(',', '.'))))
                            )
                        ),
                    },
                    ...geo,
                },
            }),
            invalidatesTags: [Tags.MultiPolygon],
        }),
        createShapeFile: builder.mutation<GeoJsonDto, ShapeFileFormCreate>({
            extraOptions: {
                label: 'Criar Poligonos por Arquivo ShapeFile',
            },
            query: ({ assetId, title, shp, dbf, prj }) => {
                const form = new FormData();
                form.append('assetId', assetId);
                form.append('title', title);
                form.append('shapeFileShp', shp);
                form.append('shapeFileDbf', dbf);
                form.append('shapeFilePrj', prj);
                return {
                    url: `gis/shapefile`,
                    method: 'POST',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.MultiPolygon],
        }),
        removeMultiPolygon: builder.mutation<GeoJsonDto, GeoJsonDto['id']>({
            extraOptions: {
                label: 'Criar Poligono',
            },
            query: (id) => ({
                url: `gis/multipolygon/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.MultiPolygon],
        }),
        //Markers endpoints
        markers: builder.query<MarkerDto[], AssetsDto['id']>({
            extraOptions: {
                label: 'Receber Marcadores Gis',
            },
            query: (assetId) => ({ url: `gis/point/${assetId}` }),
            providesTags: [Tags.Marker],
        }),
        createMarker: builder.mutation<MarkerDto, MarkerFormCreate>({
            extraOptions: {
                label: 'Criar Marcador Gis',
            },
            query: ({ data, ...marker }) => ({
                url: `gis/point`,
                method: 'POST',
                body: {
                    data: { type: 'Point', coordinates: data.map((v) => Number(v.replace(' ', '').replace(',', '.'))) },
                    ...marker,
                },
            }),
            invalidatesTags: [Tags.Marker],
        }),
        removeMarker: builder.mutation<MarkerDto, MarkerDto['id']>({
            extraOptions: {
                label: 'Remover Marcador Gis',
            },
            query: (id) => ({
                url: `gis/point/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.Marker],
        }),
        //Documents endpoints
        documents: builder.query<DocumentDto[], undefined>({
            extraOptions: {
                label: 'Receber Documentos',
            },
            query: () => ({ url: `documents` }),
            providesTags: [Tags.Document],
        }),
        documentsByOwner: builder.query<DocumentDto[], string>({
            extraOptions: {
                label: 'Receber Documentos por Cliente',
            },
            query: (ownerId) => ({ url: `documents/filesByOwnerId/${ownerId}` }),
            providesTags: [Tags.Document],
        }),
        document: builder.query<DocumentDto, DocumentDto['id']>({
            extraOptions: {
                label: 'Receber Documento',
            },
            query: (assetId) => ({ url: `documents/${assetId}` }),
            providesTags: [Tags.Document],
        }),
        createDocument: builder.mutation<DocumentDto, CreateDocument>({
            extraOptions: {
                label: 'Criar Documento',
            },
            query: ({ file, assetId, name, elementName, externalId, model3dId, tagsInIds }) => {
                const form = new FormData();
                form.append('name', name);
                form.append('file', file);
                form.append('assetId', assetId);
                if (elementName) form.append('elementName', elementName);
                if (externalId) form.append('externalId', externalId);
                if (model3dId) form.append('model3dId', model3dId);
                tagsInIds.forEach((tag, i) => {
                    form.append(`tagsInIds[${i}]`, tag);
                });
                return {
                    url: `documents`,
                    method: 'POST',
                    body: form,
                    prepareHeaders: (headers: any) => {
                        headers.set('Content-Type', 'multipart/form-data');
                        return headers;
                    },
                };
            },
            invalidatesTags: [Tags.Document],
        }),
        updateDocument: builder.mutation<DocumentDto, UPdateDocumentCall>({
            extraOptions: {
                label: 'Atualizar Documento',
            },
            query: ({ id, ...update }) => ({
                url: `documents/${id}/update`,
                method: 'PATCH',
                body: update,
            }),
            invalidatesTags: [Tags.Document],
        }),
        removeDocument: builder.mutation<DocumentDto, DocumentDto['id']>({
            extraOptions: {
                label: 'Remover Documento',
            },
            query: (id) => ({
                url: `documents/${id}/delete`,
                method: 'DELETE',
            }),
            invalidatesTags: [Tags.Document],
        }),
        //tags endpoints
        tags: builder.query<TagsDto[], undefined>({
            extraOptions: {
                label: 'Receber Tags',
            },
            query: () => ({ url: 'tags' }),
            providesTags: [Tags.Tag],
        }),
        createtag: builder.mutation<TagsDto, string>({
            extraOptions: {
                label: 'Criar Tag',
            },
            query: (name) => ({ url: 'tags', method: 'POST', body: { name } }),
            invalidatesTags: [Tags.Tag],
        }),
        //objects endpoints
        objectsByOwner: builder.query<ObjectDto[], OwnerDto['id']>({
            extraOptions: {
                label: 'Receber Objetos por Cliente',
            },
            query: (id) => ({ url: `forge/${id}/objects` }),
            providesTags: [Tags.Object],
        }),
        objectsGeneralInfoByOwner: builder.query<GeneralInfo, OwnerDto['id']>({
            extraOptions: {
                label: 'Receber Informações Gerias de Objetos por Cliente',
            },
            queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
                const { data } = (await baseQuery(`forge/${id}/objects`)) as QueryReturnValue<
                    ObjectDto[],
                    FetchBaseQueryError,
                    {}
                >;
                return {
                    data: {
                        id,
                        files: data?.length || 0,
                        totalSize: data?.map(({ size }) => size)?.reduce((p, c) => p + c, 0) || 0,
                    },
                };
            },
            providesTags: [Tags.Object],
        }),
        //acc objects endpoints
        accObjectsByOwner: builder.query<ObjectAccF[], OwnerDto['id']>({
            extraOptions: {
                label: 'Receber Objetos ACC por Cliente',
            },
            query: (id) => ({ url: `forge/${id}/acc/objects` }),
            providesTags: [Tags.ACCObject],
        }),
        // accDocumentsByOwner: builder.query<NodeData, OwnerDto['id']>({
        //     extraOptions: {
        //         label: 'Receber Documentos ACC por Cliente',
        //     },
        //     queryFn: async (id, _queryApi, _extraOptions, baseQuery) => {
        //         const dataOut: NodeData = {
        //             name: 'ACC Docs',
        //             children: [],
        //         };
        //         type ParentType = 'projects' | 'folders' | 'items' | 'hubs';

        //         const saveItem = async (
        //             parent: NodeData,
        //             parentType: ParentType,
        //             hubId: string,
        //             projectId?: string
        //         ) => {
        //             const ItemAdd = async ({ id, type, attributes: { name, displayName } }: any) => {
        //                 const item: NodeData = {
        //                     name: type === 'items' ? displayName : name,
        //                     nickname: id,
        //                 };
        //                 if (type === 'folders') {
        //                     item.children = await saveItem(item, type, hubId, projectId);
        //                 } else if (type === 'projects') {
        //                     item.children = await saveItem(item, type, hubId, id);
        //                 }
        //                 return item;
        //             };
        //             let items: any[] = [];
        //             switch (parentType) {
        //                 case 'folders':
        //                     const {
        //                         data: {
        //                             body: { data: datacontent },
        //                         },
        //                     } = (await baseQuery(
        //                         `forge/${id}/accFolderContents/${projectId}/${parent.nickname}`
        //                     )) as QueryReturnValue<any, FetchBaseQueryError, {}>;
        //                     items = datacontent.map(ItemAdd);
        //                     break;
        //                 case 'projects':
        //                     const {
        //                         data: {
        //                             body: { data: datatop },
        //                         },
        //                     } = (await baseQuery(
        //                         `forge/${id}/accProjectTopFolders/${hubId}/${parent.nickname}`
        //                     )) as QueryReturnValue<any, FetchBaseQueryError, {}>;
        //                     items = datatop.map(ItemAdd);
        //                     break;
        //                 case 'hubs':
        //                     const {
        //                         data: {
        //                             body: { data },
        //                         },
        //                     } = (await baseQuery(`forge/${id}/accProjects/${parent.nickname}`)) as QueryReturnValue<
        //                         any,
        //                         FetchBaseQueryError,
        //                         {}
        //                     >;
        //                     items = data.map(ItemAdd);
        //                     break;
        //                 default:
        //                     break;
        //             }
        //             return Promise.all(items as any[]);
        //         };
        //         const {
        //             data: {
        //                 body: { data },
        //             },
        //         } = (await baseQuery(`forge/${id}/accHubs`)) as QueryReturnValue<any, FetchBaseQueryError, {}>;
        //         for (const hub of data) {
        //             let item: NodeData = {
        //                 name: hub.name,
        //                 nickname: hub.id,
        //                 children: [],
        //             };
        //             dataOut.children = await saveItem(item, 'hubs', hub.id);
        //         }
        //         return { data: dataOut };
        //     },
        // }),
        accProjects: builder.query<any, ProjectsQuery>({
            extraOptions: {
                label: 'Receber projects de ACC',
            },
            query: ({ ownerId, hubId }) => ({
                url: `forge/${ownerId}/accProjects/${hubId}`,
            }),
        }),
        accTopFolders: builder.query<any, TopFoldersQuery>({
            extraOptions: {
                label: 'Receber top folders de ACC',
            },
            query: ({ ownerId, hubId, projectId }) => ({
                url: `forge/${ownerId}/accProjectTopFolders/${hubId}/${projectId}`,
            }),
        }),
        accFolderContents: builder.query<any, FolderContentsQuery>({
            extraOptions: {
                label: 'Receber folder contents de ACC',
            },
            query: ({ ownerId, folderId, projectId }) => ({
                url: `forge/${ownerId}/accFolderContents/${projectId}/${folderId}`,
            }),
        }),
        accItem: builder.query<ACCRequestOne<AccDocumentoF, VersionF>, ItemQuery>({
            extraOptions: {
                label: 'Receber Item de ACC',
            },
            query: ({ ownerId, itemId, projectId }) => ({
                url: `forge/${ownerId}/accItem/${projectId}/${itemId}`,
            }),
        }),
        accHubs: builder.query<any, string>({
            extraOptions: {
                label: 'Receber hubs de ACC',
            },
            query: (id) => ({
                url: `forge/${id}/accHubs`,
            }),
        }),
        clientId: builder.query<ClientIdResponse, undefined>({
            extraOptions: {
                label: 'Receber clientId de ACC',
            },
            query: () => ({
                url: 'owners/clientId',
            }),
        }),
        accStatus: builder.query<AccStatusResponse, string>({
            extraOptions: {
                label: 'Receber Status de Conexão Conta ACC',
            },
            query: (ownerId) => ({
                url: `forge/${ownerId}/accstatus`,
            }),
            providesTags: [Tags.ACCStatus],
        }),
        //status endpoints
        assetsStatus: builder.query<AtivoStateDto[], undefined>({
            extraOptions: {
                label: 'Receber Status de Ativos',
            },
            query: () => ({
                url: 'status',
            }),
            providesTags: [Tags.Status],
        }),
        assetStatus: builder.query<AtivoStateDto, AssetsDto['id']>({
            extraOptions: {
                label: 'Receber Status de Ativo',
                errorIgnore: true,
            },
            query: (id) => ({
                url: `status/${id}`,
            }),
            providesTags: [Tags.Status],
        }),
        //credits endpoints
        credits: builder.query<CloudCreditDto[], undefined>({
            extraOptions: {
                label: 'Receber Creditos de Nuvem',
            },
            query: () => ({ url: 'cloud-credits' }),
            providesTags: [Tags.Credit],
        }),
        createCredit: builder.mutation<CloudCreditDto, CreateCloudCredit>({
            extraOptions: {
                label: 'Criar Credito de Nuvem',
            },
            query: (credit) => ({ url: 'cloud-credits', method: 'POST', body: credit }),
            invalidatesTags: [Tags.Credit],
        }),
        updateCredit: builder.mutation<CloudCreditDto, CallUpdateCredit>({
            extraOptions: {
                label: 'Atualizar Credito de Nuvem',
            },
            query: ({ id, creditConsumedByCall, description }) => {
                let body: any = {};
                if (description) body.description = description;
                if (creditConsumedByCall) body.creditConsumedByCall = creditConsumedByCall;
                return { url: `cloud-credits/${id}`, method: 'PATCH', body };
            },
            invalidatesTags: [Tags.Credit],
        }),
        //actions endpoints
        actions: builder.query<ActionDto[], string>({
            extraOptions: {
                label: 'Receber Atividades Executadas em Sistema',
            },
            query: (objectId) => ({ url: `actions/${objectId}` }),
        }),
    }),
});

export const {
    //auth
    useLoginMutation,
    useSuperLoginMutation,
    useLogoutMutation,
    useProfileQuery,
    useLazyProfileQuery,
    useChangeUserPasswordMutation,
    useChangeSuperUserPasswordMutation,
    //ativos
    useAtivosQuery,
    useAtivosByOwnerQuery,
    useAtivoQuery,
    useLazyAtivoQuery,
    useUpdateAtivoMutation,
    useCreateAtivoMutation,
    useUpdateAtivoLimitsMutation,
    useUpdateAtivoAvatarMutation,
    useRemoveAtivoMutation,
    //assets properties
    useAtivoPropertiesQuery,
    useLazyAtivoPropertiesQuery,
    useAddAtivoPropertiesMutation,
    useUpdateAtivoPropertiesMutation,
    //owner
    useOwnersQuery,
    useOwnerQuery,
    useOwnerInfoQuery,
    useOwnerSetCodeMutation,
    useCreateOwnerMutation,
    useUpdateOwnerMutation,
    useRemoveOwnerMutation,
    //usuarios
    useUsuariosQuery,
    useUsuariosByOwnerQuery,
    useUsuarioQuery,
    useUpdateUserMutation,
    useUpdateUserAvatarMutation,
    useResetUserPasswordMutation,
    useCreateUsuarioMutation,
    useRemoveUsuarioMutation,
    //superusuarios
    useSuperUsuariosQuery,
    useSuperUsuarioQuery,
    useUpdateSuperUserMutation,
    useUpdateSuperUserAvatarMutation,
    useResetSuperUserPasswordMutation,
    useCreateSuperUsuarioMutation,
    useRemoveSuperUsuarioMutation,
    //modelos
    useModelosQuery,
    useModeloQuery,
    useUpdateModeloMutation,
    useCreateModeloMutation,
    useRemoveModeloMutation,
    //disciplinas
    useDisciplinasQuery,
    useCreateDisciplinaMutation,
    useRemoveDisciplinaMutation,
    //workflows
    useWorkflowsQuery,
    useWorkflowQuery,
    useUpdateWorkflowMutation,
    useCreateWorkflowMutation,
    useRemoveWorkflowMutation,
    //atividades
    useAtividadesQuery,
    useAtividadesByModelsQuery,
    useAtividadesByModelQuery,
    useAtividadesByAssetQuery,
    useAtividadeQuery,
    useUpdateAtividadeMutation,
    useUpdateAtividadeStatusMutation,
    useCreateAtividadeMutation,
    useRemoveAtividadeMutation,
    useAddAtividadeLinkMutation,
    useRemoveAtividadeLinkMutation,
    //comentarios
    useCommentsCountQuery,
    useCommentsWithFileByOwnerQuery,
    useCommentsQuery,
    useCommentQuery,
    useCommentsWithFilesQuery,
    useCommentsWithFilesActiListQuery,
    useCreateCommentMutation,
    //sensores
    useSensoresQuery,
    useSensoresByAssetQuery,
    useSensorQuery,
    useLazySensorQuery,
    useUpdateSensorMutation,
    useCreateSensorMutation,
    useCreateSensorsFromFileMutation,
    useRemoveSensorMutation,
    useAddSensorLinkMutation,
    useRemoveSensorLinkMutation,
    //sensorData
    useSensorDataQuery,
    useSensorDataByAssetQuery,
    useCreateSensorDataMutation,
    useCreateSensorDataByFileMutation,
    useCreateSensorDataMultiFilesMutation,
    useCreateSensorDataOneFileMutation,
    useRemoveSensorDataMutation,
    useRemoveSensorDataMultipleMutation,
    //Multipoligons
    useMultiPolygonsQuery,
    useCreateMultiPolygonMutation,
    useCreateShapeFileMutation,
    useRemoveMultiPolygonMutation,
    //Markers
    useMarkersQuery,
    useCreateMarkerMutation,
    useRemoveMarkerMutation,
    //Documents
    useDocumentsQuery,
    useDocumentsByOwnerQuery,
    useDocumentQuery,
    useCreateDocumentMutation,
    useUpdateDocumentMutation,
    useRemoveDocumentMutation,
    //Tags
    useTagsQuery,
    useCreatetagMutation,
    //objects
    useObjectsByOwnerQuery,
    useObjectsGeneralInfoByOwnerQuery,
    //acc objects
    useAccObjectsByOwnerQuery,
    // useAccDocumentsByOwnerQuery,
    useAccHubsQuery,
    useLazyAccProjectsQuery,
    useAccProjectsQuery,
    useLazyAccTopFoldersQuery,
    useAccTopFoldersQuery,
    useLazyAccFolderContentsQuery,
    useAccFolderContentsQuery,
    useClientIdQuery,
    useAccStatusQuery,
    useAccItemQuery,
    //status
    useAssetsStatusQuery,
    useAssetStatusQuery,
    //credits
    useCreditsQuery,
    useCreateCreditMutation,
    useUpdateCreditMutation,
    //actions
    useActionsQuery,
} = serviceApi;
