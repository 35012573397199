/* istanbul ignore file */
const apiConfig = {
    PORT: process.env.PORT,
    FORGE_ACC_REDIRECT_URI: process.env.REACT_APP_FORGE_ACC_REDIRECT_URI,
    REACT_APP_API_URL: process.env.NODE_ENV === 'production' ? '/api/v1' : process.env.REACT_APP_API_URL + '/api/v1',
    REDIS_HOST: process.env.REDIS_HOST,
    REDIS_PORT: process.env.REDIS_PORT,
    NODE_ENV: process.env.NODE_ENV,
};

export default apiConfig;
