import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Api } from 'service/api';
import { Image } from 'types';
import { Now } from 'utils/time';
import { saveListOneNoLoading } from '../utils/actions';
import { resetState } from '../utils/reset-state';
import { RootState } from 'redux/store';
import { create, remove } from '../alertas/alertas-slice';
import { v4 as uuidv4 } from 'uuid';

export const getImage = createAsyncThunk('Image/getOne', async (id: string, { getState, dispatch }) => {
    const {
        Auth: { access },
    } = getState() as RootState;
    let image: string;
    try {
        image = await Api.getImage(id, access!);
    } catch (error) {
        const id = uuidv4();
        dispatch(
            create({
                id,
                variant: 'danger',
                titulo: 'Imagem Não encontrada',
                texto: 'Não foi possivel encontrar imagem',
            })
        );
        setTimeout(() => {
            dispatch(remove(id));
        }, 5000);
        throw error;
    }
    return {
        id,
        image,
        called: Now(),
        loading: false,
    };
});

export interface ImageState {
    imagens: Image[];
}
const initialState: ImageState = {
    imagens: [],
};
const ImageSlice = createSlice({
    name: 'ImageSlice',
    initialState,
    reducers: {
        reset: (state) => resetState(state, initialState),
    },
    extraReducers(builder) {
        builder.addCase(getImage.fulfilled, (state, { payload }) => saveListOneNoLoading(state, 'imagens', payload));
        builder.addCase(getImage.pending, (state, { meta: { arg } }) => {
            saveListOneNoLoading(state, 'imagens', {
                id: arg,
                called: Now(),
                loading: true,
            });
        });
        builder.addCase(getImage.rejected, (state, { error: { message }, meta: { arg } }) => {
            const match = message?.match(/.*code (\d{3})/);
            if (match) {
                saveListOneNoLoading(state, 'imagens', {
                    id: arg,
                    called: Now(),
                    error: Number(match[1]),
                    loading: false,
                });
            }
        });
    },
});
export const { reset } = ImageSlice.actions;

export default ImageSlice.reducer;
