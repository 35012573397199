import { AnexoF, FileExtension } from "types";
/* istanbul ignore file */

export const anexos: AnexoF[] = [
    {
        id: '1',
        nome: 'image.jpg',
        tamanho: 1024,
        url: 'https://shortpixel.com/img/robot_lookleft_wink_big.png',
        extension: FileExtension.IMAGE,
    },
    {
        id: '2',
        nome: 'hello.mp3',
        tamanho: 1024,
        url: 'https://cdn.freesound.org/previews/449/449481_9288538-lq.mp3',
        extension: FileExtension.AUDIO,
    },
    {
        id: '3',
        nome: 'video.mp4',
        tamanho: 1024,
        url: 'https://2050today.org/wp-content/uploads/2020/07/Video-Placeholder.mp4?_=1',
        extension: FileExtension.VIDEO,
    },
];