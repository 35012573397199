import { UserDto, UsuarioF, UsuarioFuncao } from 'types';
/* istanbul ignore file */

export const superuser: UserDto = {
    id: '99',
    avatarId: '1',
    ownerId: '1',
    nameComplete: 'admin',
    email: 'admin1@teste.com',
    username: 'admin1@teste.com',
    role: UsuarioFuncao.GLOBAL,
    position: 'Dono',
    inAssetsIds: ['1', '2'],
    doerInIds: [],
    approverInIds: [],
    observerInIds: [],
    isSuperuser: true,
};
export const usuarios: UserDto[] = [
    {
        id: '1',
        ownerId: '1',
        avatarId: '1',
        nameComplete: 'Jorge',
        email: 'jorge1@teste.com',
        username: 'jorge1@teste.com',
        role: UsuarioFuncao.GLOBAL,
        position: 'Dono',
        inAssetsIds: ['1', '2'],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
    {
        id: '2',
        ownerId: '1',
        avatarId: '1',
        nameComplete: 'João',
        email: 'joao2@teste.com',
        username: 'joao2@teste.com',
        role: UsuarioFuncao.ADMINISTRADOR,
        position: 'Gerente',
        inAssetsIds: ['1', '2'],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
    {
        id: '3',
        avatarId: '1',
        ownerId: '1',
        nameComplete: 'Bruno',
        email: 'bruno3@teste.com',
        username: 'bruno3@teste.com',
        role: UsuarioFuncao.COMUM,
        position: 'Funcionario',
        inAssetsIds: ['3'],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
    {
        id: '4',
        avatarId: '1',
        ownerId: '1',
        nameComplete: 'Will',
        email: 'will4@teste.com',
        username: 'will4@teste.com',
        role: UsuarioFuncao.COMUM,
        position: 'Funcionario',
        inAssetsIds: ['1', '2'],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
    {
        id: '5',
        avatarId: '1',
        ownerId: '1',
        nameComplete: 'Carlos',
        email: 'carlos5@teste.com',
        username: 'carlos5@teste.com',
        role: UsuarioFuncao.COMUM,
        position: 'Funcionario',
        inAssetsIds: [],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
    {
        id: '6',
        avatarId: '1',
        ownerId: '1',
        nameComplete: 'John',
        email: 'john6@teste.com',
        username: 'john6@teste.com',
        role: UsuarioFuncao.COMUM,
        position: 'Funcionario',
        inAssetsIds: [],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
    {
        id: '7',
        avatarId: '1',
        ownerId: '1',
        nameComplete: 'Peter',
        email: 'peter7@teste.com',
        username: 'peter7@teste.com',
        role: UsuarioFuncao.COMUM,
        position: 'Funcionario',
        inAssetsIds: [],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
    {
        id: '8',
        avatarId: '1',
        ownerId: '1',
        nameComplete: 'Mike',
        email: 'mike8@teste.com',
        username: 'mike8@teste.com',
        role: UsuarioFuncao.COMUM,
        position: 'Funcionario',
        inAssetsIds: [],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
    {
        id: '9',
        avatarId: '1',
        ownerId: '1',
        nameComplete: 'Brenda',
        email: 'brenda9@teste.com',
        username: 'brenda9@teste.com',
        role: UsuarioFuncao.COMUM,
        position: 'Funcionario',
        inAssetsIds: [],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
    {
        id: '10',
        avatarId: '1',
        ownerId: '1',
        nameComplete: 'Gus',
        email: 'gus10@teste.com',
        username: 'gus10@teste.com',
        role: UsuarioFuncao.COMUM,
        position: 'Dono',
        inAssetsIds: [],
        doerInIds: [],
        approverInIds: [],
        observerInIds: [],
        isSuperuser: false,
    },
];
