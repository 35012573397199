import { BrowserRouter } from 'react-router-dom';
import { AllRoutes } from './index';

const Routes = () => {
    return (
        <div data-testid="browser">
            <BrowserRouter>
                <AllRoutes />
            </BrowserRouter>
        </div>
    );
};

export default Routes;
