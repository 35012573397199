import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetState } from '../utils/reset-state';
import { AtivoF } from 'types';

export interface AtivosState {
    ativoSelecionado: AtivoF['id'] | null;
    lastSelected: AtivoF['id'] | null;
    allOption: boolean;
}
const initialState: AtivosState = {
    ativoSelecionado: null,
    lastSelected: null,
    allOption: true,
};

interface OptionChange {
    allOption: boolean;
    ativo: AtivoF['id'] | null;
}

const AtivoSlice = createSlice({
    name: 'AtivoSlice',
    initialState,
    reducers: {
        reset: (state) => resetState(state, initialState),
        changeAllOption(state, { payload: { allOption, ativo } }: PayloadAction<OptionChange>) {
            state.allOption = allOption;
            state.ativoSelecionado = ativo;
        },
        selectAtivo(state, { payload }: PayloadAction<string | null>) {
            state.ativoSelecionado = payload;
            state.lastSelected = payload;
        },
    },
});
export const { selectAtivo, changeAllOption, reset } = AtivoSlice.actions;
export default AtivoSlice.reducer;
