import { ActivityDto, AtividadePorcentagem } from 'types';

export * from './layout';
export * from './string';
export * from './alert';
export * from './array';
export * from './form/index';
export * from './state';

export const CalcularProgresso = (atividades: ActivityDto[]) => {
    return atividades.length > 0
        ? Math.round(
              atividades.map((a) => AtividadePorcentagem[a.status] * (1 / atividades.length)).reduce((p, c) => p + c) *
                  100
          )
        : 100;
};
