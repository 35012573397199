import { BaseF, UsuarioF, AnexoF, WorkflowF, ComentarioF, AtivoF, Modelo3dF } from '.';
import { positionShiftDto } from './api/positionShift';

export const AtividadeStatus = {
    ABERTA: 'open',
    EM_EXECUÇÃO: 'in_execution',
    PARA_AVALIAÇÃO: 'for_assessment',
    EM_AVALIAÇÃO: 'in_assessment',
    CONCLUIDA: 'finished',
} as const;

const AtividadePorcentagem: any = {};
AtividadePorcentagem[AtividadeStatus.ABERTA] = 0;
AtividadePorcentagem[AtividadeStatus.EM_EXECUÇÃO] = 0.25;
AtividadePorcentagem[AtividadeStatus.PARA_AVALIAÇÃO] = 0.5;
AtividadePorcentagem[AtividadeStatus.EM_AVALIAÇÃO] = 0.75;
AtividadePorcentagem[AtividadeStatus.CONCLUIDA] = 1;

export { AtividadePorcentagem };

export const OrdemAtividade: AtividadeStatusKeys[][] = [
    ['ABERTA', 'EM_EXECUÇÃO'],
    ['EM_EXECUÇÃO', 'PARA_AVALIAÇÃO'],
    ['PARA_AVALIAÇÃO', 'EM_AVALIAÇÃO'],
    ['EM_AVALIAÇÃO', 'CONCLUIDA'],
];
export const AtividadePrioridade = {
    URGENTE: 'urgent',
    ALTA: 'high',
    NORMAL: 'normal',
    BAIXA: 'low',
    NENHUMA: 'none',
} as const;

type AtividadePrioridadeKeys = keyof typeof AtividadePrioridade;
export type AtividadePrioridadeValues = typeof AtividadePrioridade[AtividadePrioridadeKeys];

export type AtividadeStatusKeys = keyof typeof AtividadeStatus;
export type AtividadeStatusValues = typeof AtividadeStatus[AtividadeStatusKeys];

export interface AtividadeF extends BaseF {
    title: string;
    description: string;
    deadline: Date;
    priority: AtividadePrioridadeValues;
    status: AtividadeStatusValues;
    messages: ComentarioF['id'][];
    responsableId: UsuarioF['id'] | null;
    anexos: AnexoF['id'][];
    workflowId: WorkflowF['id'];
    assetId?: AtivoF['id'];
    model3dId?: Modelo3dF['id'];
    elementName?: string;
    objectId?: number;
    fragId?: number;
    externalId?: string;
    positionShift?: positionShiftDto;
}
